import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

export interface ResizeImgType {
  m?: 'lfit' | 'mfit' | 'fill' | 'pad' | 'fixed';
  w?: number;
  h?: number;
  l?: number;
  s?: number;
  limit?: 0 | 1;
  color?: string;
}

@Pipe({ name: 'getResizeImg' })
export class GetResizeImg implements PipeTransform {
  constructor(
  ) {}
  /**
   * 阿里云图片缩放
   * @param path 图片路径
   * @param isOriginal 是否展示原图
   * @param resiteNames 图片参数 { m, w, h, l, s, limit, color }
   * @param  m 指定缩略的模式
   * lfit：等比缩放，限制在指定w与h的矩形内的最大图片。
   * mfit：等比缩放，延伸出指定w与h的矩形框外的最小图片。
   * fill：固定宽高，将延伸出指定w与h的矩形框外的最小图片进行居中裁剪。
   * pad：固定宽高，缩略填充。
   * fixed：固定宽高，强制缩略。
   * @param w 指定目标缩略图的宽度 取值范围 1-4096
   * @param h 指定目标缩略图的宽度 取值范围 1-4096
   * @param l 指定目标缩略图的最长边 取值范围 1-4096
   * @param s 指定目标缩略图的最短边 取值范围 1-4096
   * @param limit 指定当目标缩略图大于原图时是否处理。值是 1 表示不处理；值是 0 表示处理。 取值范围 0/1, 默认是 1
   * @param color 当缩放模式选择为 pad（缩略填充）时，可以选择填充的颜色(默认是白色)参数的填写方式：采用 16 进制颜色码表示，如 00FF00（绿色）
   */
  transform(
    path: string,
    isOriginal: boolean = false,
    resiteNames: ResizeImgType = {
      m: 'lfit',
      w: 100,
      limit: 1,
    },
  ) {
    let url = path;

    if (!url) {
      url = '';
    }

    if (url.indexOf('https://') < 0 && url.indexOf('http://') < 0) {
      url = environment.IMG_URL + url;
    }

    if (isOriginal) {
      return url;
    }

    const prefix = url.includes('?') ? '&' : '?';

    url += `${prefix}x-oss-process=image/resize`;

    Object.keys(resiteNames).forEach(k => {
      url += `,${k}_${(resiteNames as any)[k]}`;
    });
    return url;
  }

}
