import { Injectable, Inject, Injector } from '@angular/core'
// import { URLSearchParams } from 'whatwg-url';
import { ITokenService } from '../../service/interface';
import { _HttpClient } from '../../service/http/http.client';
import { EZ_SERVICE_TOKEN } from '../../service/token.service';
import { TitleService } from '../../service/title/title.service';
import { SettingsService } from '../../service/settings/settings.service';
// import { getTemplates } from 'src/app/projects/ez-dashboard/utils';
// import { MenuService } from '../../service/menu/menu.service';
// import { Router } from '@angular/router';
// import allRoutes from 'src/app/routes/allRoutes';

// const shortcuts = {
//   windows: {
//     ctrl: 'ctrl+',
//   },
//   mac: {
//     ctrl: '⌘',
//   }
// }

/**
 * Used for application startup
 * Generally used to get the basic data of the application, like: Menu Data, User Data, etc.
 */
@Injectable()
export class StartupService {
  // templates = null;
  // setting = {
  //   isMac: false,
  //   ...shortcuts.windows,
  // };

  constructor(
    @Inject(EZ_SERVICE_TOKEN) private tokenService: ITokenService,
    private httpClient: _HttpClient,
    private titleService: TitleService,
    // private menuService: MenuService,
    private settingService: SettingsService,
    // private router: Router,
    // private injector: Injector,
  ) {}

  // private get router() {
  //   return this.injector.get(Router);
  // }

  private viaHttp(resolve: any, reject: any) {
    // this.templates = getTemplates();
    // this.setCtrlString();

    this.httpClient.post('business/common/taji/data').subscribe(//business/common/taji/data
      (resp) => {
        // Application data
        const res: any = resp.data;
        // this.menuService.add(res.menu);
        // this.menuService.setMenuData(res.menu);
        this.settingService.setUserInfo(res.user);
        this.settingService.setCorpInfo(res.corp);

        this.settingService.setUser({
          id: res.id,
        });
        this.settingService.setRoleId(res.roleId);
        this.settingService.setCorpName(res.corpName);
        this.setTitle();
        // this.setRouterConfig();
      },
      () => {
        resolve(null);
      },
      () => {
        resolve(null);
      }
    );
  }
  // setRouterConfig() {
  //   if (this.settingService.corpInfo?.install === 0) {
  //     this.router.config = allRoutes.installRoutes;
  //   } else {
  //     this.router.config = allRoutes.defaultRoutes;
  //   }
  // }

  // setCtrlString() {
  //   const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  //   if (isMac) {
  //     this.setting.isMac = true;
  //     this.setting = {
  //       ...this.setting,
  //       ...shortcuts.mac,
  //       isMac: true,
  //     };
  //   }
  // }

  setTitle() {
    this.settingService.setApp({
      name: '塔机方案',
      /** Description for app */
      description: '塔机方案',
    });
    this.titleService.default = '';
    this.titleService.suffix = '塔机方案'; // res.app.name;
  }

  // private viaMock(resolve: any, reject: any) {
  //   // const tokenData = this.tokenService.get();
  //   // if (!tokenData.token) {
  //   //   this.injector.get(Router).navigateByUrl('/passport/login');
  //   //   resolve({});
  //   //   return;
  //   // }
  //   // mock
  //   const app: any = {
  //     name: `ezcel`,
  //     description: `Ng-zorro admin panel front-end framework`
  //   };
  //   const user: any = {
  //     name: 'Admin',
  //     avatar: './assets/tmp/img/avatar.jpg',
  //     email: 'cipchk@qq.com',
  //     token: '123456789'
  //   };
  //   // Application information: including site name, description, year
  //   this.settingService.setApp(app);
  //   // User information: including name, avatar, email address
  //   this.settingService.setUser(user);
  //   // ACL: Set the permissions to full, https://ng-alain.com/acl/getting-started
  //   this.aclService.setFull(true);
  //   // Menu data, https://ng-alain.com/theme/menu
  //   this.menuService.add([
  //     {
  //       text: 'Main',
  //       group: true,
  //       children: [
  //         {
  //           text: 'Dashboard',
  //           link: '/dashboard',
  //           icon: { type: 'icon', value: 'appstore' }
  //         },
  //         {
  //           text: 'Quick Menu',
  //           icon: { type: 'icon', value: 'rocket' },
  //           shortcutRoot: true
  //         }
  //       ]
  //     }
  //   ]);
  //   // Can be set page suffix title, https://ng-alain.com/theme/title
  //   this.titleService.suffix = app.name;
  //
  //   resolve({});
  // }

  load(): Promise<any> {
    const search = window.location.href.substring(window.location.href.indexOf('?'));
    const url = new URLSearchParams(search);
    const token = url.get('tk');
    if (token) {
      this.tokenService.set({ token });
      window.location.href = window.location.href.split(`tk=${token}`).join('');
    }
    //
    // return;
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return new Promise((resolve, reject) => {
      // http
      // this.viaHttp(resolve, reject);
      this.viaHttp(resolve, reject);
    });
  }

  loadNotLogin(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.setTitle();
      resolve(null);
    });
  }

}
