import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FirstLoginComponent } from './home/first-login/first-login.component';
import { NzModalService } from 'ng-zorro-antd/modal';

export interface IProgrammeParam {
  itemId: string | null;
  sort: string;
  status: number | null;
  type: number | null;
  current: number;
  size: number;
  keyword?: string | null;
}

interface IIdparam {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  needPassword$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private modal: NzModalService
  ) { }

  setNeedPassword(): void {
    this.needPassword$.next(true);
  }

  setPassword(): void {
    const close$ = new Subject<void>();
    const modal = this.modal.create({
      nzTitle: '设置密码',
      nzContent: FirstLoginComponent,
      nzClosable: true,
      nzMaskClosable: false,
      nzCancelText: null,
      nzKeyboard: false,
      nzFooter: null,
      nzBodyStyle: {
        padding: '0'
      },
      nzComponentParams: {
        close$
      }
    });
    // tslint:disable-next-line: deprecation
    close$.subscribe(() => modal.close());
    // tslint:disable-next-line: deprecation
    modal.afterClose.subscribe(() => {
      close$.next();
      close$.complete();
    });
  }
  //  选择框方案所属项目列表
  getProjectList(param: any): Observable<any> {
    return this.http.post('tower/item/project/list', param);
  }

  // 表格项目数据
  getProgrammeList(param: IProgrammeParam): Observable<any> {
    return this.http.post('tower/project/home/page', param);
  }
}
