import { TitleService } from '@service/title/title.service';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ez-container',
  templateUrl: 'ez-container.component.html',
  styleUrls: ['ez-container.component.less'],
})
export class EzContainerComponent {
  @Input() backUrl = '';
  @Input() title = '';
  @Input() ezContentStyle: {[key: string]: string} = {};
  @Input() noContentPadding = false;
  constructor(
    private titleService: TitleService,
    private router: Router,
  ) {}

  get prefix(): string {
    return this.title || this.titleService.prefix;
  }

  onBack(): void {
    if (!this.backUrl) {
      return;
    }
    this.router.navigateByUrl(this.backUrl);
  }
}
