import { Component, ChangeDetectionStrategy, OnInit, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EZ_SERVICE_TOKEN } from 'src/app/service/token.service';
import { ITokenService } from 'src/app/service/interface';
import { SettingsService } from '../../../service/settings/settings.service';
// import { MenuService } from 'src/app/service/menu/menu.service';
// import { SettingsService } from '@delon/theme';

@Component({
  selector: 'app-layout-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit{
  menus = [
    // {
    //   title: '概况',
    //   url: '/dashboard',
    //   icon: 'iconboard',
    //   selected: false,
    //   visible: true,
    // },
    // {
    //   title: '数据洞察',
    //   url: '/insight/kpi',
    //   icon: 'iconduidiezhuzhuangtu1',
    //   selected: false,
    //   visible: true,
    // },
    // {
    //   title: '客流分析',
    //   url: '/customerAnalysis',
    //   icon: 'iconkehu',
    //   selected: false,
    //   visible: true,
    // },
    // {
    //   title: '公共区域',
    //   url: '/public',
    //   icon: 'icongonggongquyu',
    //   selected: false,
    //   visible: true,
    // },
    // {
    //   title: '看板',
    //   url: '/report',
    //   icon: 'icondashboard',
    //   selected: false,
    //   visible: true,
    // },
    // {
    //   title: '报表',
    //   url: '/analysis',
    //   icon: 'iconfenxi',
    //   selected: false,
    //   visible: true,
    // },
    // {
    //   title: '应用',
    //   url: '/application',
    //   icon: 'iconyingyong',
    //   selected: false,
    //   visible: true,
    // },
    // {
    //   title: '设置',
    //   url: '/settings',
    //   icon: 'iconsetting',
    //   selected: false,
    //   visible: this.settings.userInfo?.isAdmin,
    // }
  ];

  constructor(
    @Inject(EZ_SERVICE_TOKEN) private tokenService: ITokenService,
    public settings: SettingsService,
    private httpClient: HttpClient,
    private message: NzMessageService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {}

  logout(): void {
    this.httpClient.post('passport/logout', null).subscribe(
      (res: any) => {
        const { code, msg, data } = res;
        if (code !== 200) {
          this.message.error(msg);
          return;
        }
        this.tokenService.clear();
        localStorage.clear();
        // this.aclService.setFull(false);
        this.router.navigateByUrl(this.tokenService.login_url as string);
      },
      error => {
        this.message.warning(error.toString());
      }
    );
  }
}
