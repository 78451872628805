import { BigNumber } from 'bignumber.js';
import { SPLIT_KEY } from './constants';

export function blobToDataURL(blob: Blob, callback: (value: any) => void): void {
  const reader = new FileReader();
  reader.onload = (evt) => {
    const t = evt.target;
    const base64 = t ? t.result : null;
    callback(base64);
  };
  reader.readAsDataURL(blob);
}

// 塔机 是否为系统参数
export function hasSysParam(type: string | any[], name: string): boolean {
  // if (type) {
  // throw new Error('非系统参数');
  return type.indexOf(name) !== -1;
  // }
}

export function fullScreen(element: any): void {
  if (!element) {
    throw new Error('element不存在！');
  }
  try {
    const d: any = document;
    // 判断是否已经是全屏
    // 如果是全屏，退出
    if (d.fullscreen) {
      if (d.exitFullscreen) {
        d.exitFullscreen();
      } else if (d.webkitCancelFullScreen) {
        d.webkitCancelFullScreen();
      } else if (d.mozCancelFullScreen) {
        d.mozCancelFullScreen();
      } else if (d.msExitFullscreen) {
        d.msExitFullscreen();
      }
    } else {
      // 否则，进入全屏
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen();
      }
    }
  } catch (error) {
    console.warn(error);
  }
}

// tslint:disable-next-line: variable-name
const formmatFlat_autoKey = (data = []): any => {
  const result: { title: any; key: string; pid?: string; isLeaf?: boolean; }[] = [];
  data.forEach((item: any) => {
    const { supplier, type, modelName, armLength, id } = item;
    result.push({
      title: supplier,
      key: supplier,
    });
    result.push({
      title: type,
      key: setKey([supplier, type]),
      pid: `${supplier}`
    });
    result.push({
      title: modelName,
      key: setKey([supplier, type, modelName]),
      pid: setKey([supplier, type])
    });
    result.push({
      title: armLength,
      key: item.id,
      isLeaf: true,
      pid: setKey([supplier, type, modelName])
    });
  });
  return result;
};

const setKey = (data: string[]) => {
  return data.join(SPLIT_KEY);
};

const unique = (array = [], key: string | number): any[] => {
  return array.filter((item, index, oprigin) => {
    return oprigin.findIndex(i => i[key] === item[key]) === index;
  });
};

const getTree = (array: any[]): any => {
  // tslint:disable-next-line: one-variable-per-declaration
  const map = new Map(), result: any[] = [];
  array.forEach(item => {
    map.set(item.key, item);
  });
  array.forEach(item => {
    if (!item.pid) {
      result.push(item);
    } else {
      if (!map.get(item.pid).children) {
        map.get(item.pid).children = [];
      }
      map.get(item.pid).children.push(item);
    }
  });
  return result;
};

export const delTree = (data: any): any => {
  return getTree(unique(formmatFlat_autoKey(data), 'key'));
};

export const degToRadian = (deg: number) => new BigNumber(deg).multipliedBy(new BigNumber(Math.PI)).dividedBy(new BigNumber(180)).toNumber();

export const cos = (deg: number) => Math.cos(degToRadian(deg));
export const sin = (deg: number) => Math.sin(degToRadian(deg));

// 数字转换为 excel 字母
export function numToExcelStr(num: number): string {
  const stringArray: any[] = [];
  const numToStr = (paramNum: number) => {
    const newNum = paramNum - 1;
    const a = parseInt(`${newNum / 26}`, 10);
    const b = newNum % 26;
    stringArray.push(String.fromCharCode(64 + parseInt(`${b + 1}`, 10)));
    if (a > 0) {
      numToStr(a);
    }
  };
  numToStr(num);
  return stringArray.reverse().join('');
}
