import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'component-preview',
  templateUrl: 'preview.component.html',
  styleUrls: ['preview.component.less'],
})
export class PreviewComponent implements AfterViewInit {

  readonly prefixUrl = 'https://view.officeapps.live.com/op/view.aspx?src=';

  @ViewChild('iframe', { static: false })
  iframe!: ElementRef;

  @Input()
  get url(): string { return this.saveUrl; }
  set url(data: string) {
    console.log('刷新');
    this.saveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.prefixUrl}${data}?t=${Date.now()}`);
  }

  private saveUrl: any;

  loading = true;

  constructor(private sanitizer: DomSanitizer) { }

  ngAfterViewInit(): void {

    // if (this.iframe.nativeElement.attachEvent) {
    //   // tslint:disable-next-line: only-arrow-functions
    //   this.iframe.nativeElement.attachEvent('onload', () => {
    //     this.loading = false;
    //   });
    // } else {
    //   // tslint:disable-next-line: only-arrow-functions
    //   this.iframe.nativeElement.onload = () => {
    //     this.loading = false;
    //   };
    // }
  }

}
