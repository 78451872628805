import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'abs' })
export class Abs implements PipeTransform {
  transform(
    num: any,
  ): number {
    return Math.abs(num);
  }
}
