import defaultAuthRoute from './default-auth-route';
import exceptionRoutes from './exception-routes';
import fullScreenAuthRoute from './fullscreen-auth-route';
import fullScreenNotAuthRoute from './fullscreen-not-auth-route';

const allRoutes = {
  allRoutes: [
    defaultAuthRoute,
    fullScreenNotAuthRoute,
    fullScreenAuthRoute,
    ...exceptionRoutes,
  ]
};

export default allRoutes;
