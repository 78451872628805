import { Route } from '@angular/router';
import { LayoutFullScreenComponent } from '../layout/fullscreen/fullscreen.component';
import { AuthGuard } from '../service/auth-guard.service';

const fullScreenAuthRoute: Route = {
  path: '',
  component: LayoutFullScreenComponent,
  canActivate: [AuthGuard],
  children: [],
};

export default fullScreenAuthRoute;
