<div
  [ngStyle]="{
    width: lineWidth + 'px',
    transform: 'matrix(' + matrix + ')'
  }"
>
  <svg [attr.width]="lineWidth" [attr.height]="10 * borderHeight">
    <defs>
      <marker
        id="arrow-end"
        markerUnits="strokeWidth"
        markerWidth="12"
        markerHeight="12"
        viewBox="-1 -1 12 12"
        refX="0"
        refY="5"
        orient="auto"
      >
        <path
          d="M0,0 L10,5 L0,10"
          [attr.fill]="color"
        ></path>
      </marker>
    </defs>
    <!-- [class]="data.props.bizData.borderStyle" -->
    <line
      #line
      class="solid"
      [attr.stroke-dasharray]="
        borderStyle === 'solid'
          ? '0'
          : borderStyle === 'dashed'
          ? 2 * borderHeight +
            ' ' +
            1 * borderHeight
          : 0 + ' ' + 2.333333333333333 * borderHeight
      "
      [attr.x1]="borderHeight"
      [attr.x2]="lineWidth - borderHeight - 9 * borderHeight"
      [attr.y1]="5 * borderHeight"
      [attr.y2]="5 * borderHeight"
      [attr.stroke-width]="borderHeight"
      [attr.stroke]="color"
      [attr.marker-end]="'url(#arrow-end)'"
    ></line>
  </svg>
</div>
