import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomPreloadingStrategy } from '../service/custom-preloading-strategy.service';
import allRoutes from './all-routes';

const routes: Routes = allRoutes.allRoutes;

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {
    useHash: true,
    // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
    // Pls refer to https://ng-alain.com/components/reuse-tab
    scrollPositionRestoration: 'top',
    preloadingStrategy:  CustomPreloadingStrategy,
  }
  )],
  exports: [RouterModule]
})
export class RouteRoutingModule { }
