import { Component, Input } from '@angular/core';

@Component({
  selector: 'component-name-avatar',
  templateUrl: 'name-avatar.component.html',
  styleUrls: ['name-avatar.component.less'],
})
export class NameAvatarComponent {
  @Input() name = '';
  @Input() width = 24;

  get fontSize(): number {
    return Math.floor(this.width * (14 / 24));
  }
}
