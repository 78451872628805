import { Route } from '@angular/router';
import { LayoutDefaultComponent } from '../layout/defaullt/default.component';
import { AuthGuard } from '../service/auth-guard.service';

const defaultAuthRoute: Route = {
  path: '',
  component: LayoutDefaultComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule) },
    { path: 'users', loadChildren: () => import('../pages/users/users.module').then(m => m.UsersModule) },
    // { path: 'templates', loadChildren: () => import('../pages/templates/templates.module').then(m => m.TemplatesModule)},
    // { path: 'params', loadChildren: () => import('../pages/params/params.module').then(m => m.ParamsModule)},
    { path: 'plan', loadChildren: () => import('../pages/plan/plan.module').then(m => m.PlanModule) },
    { path: 'console', loadChildren: () => import('../pages/console/console.module').then(m => m.ConsoleModule) },
    { path: 'project', loadChildren: () => import('../pages/project/project.module').then(m => m.ProjectModule) },
    { path: 'programme', loadChildren: () => import('../pages/programme/programme.module').then(m => m.ProgrammeModule) },
    { path: 'priview', loadChildren: () => import('../pages/priview/priview.module').then(m => m.PriviewModule) },
    { path: 'device', loadChildren: () => import('../pages/device/device.module').then(m => m.DeviceModule) },
    { path: 'compute', loadChildren: () => import('../pages/compute/compute.module').then(m => m.ComputeModule) },
    { path: 'taji-manage', loadChildren: () => import('../pages/taji-manage/taji-manage.module').then(m => m.TajiManageModule) },
    { path: 'taji-suspension', loadChildren: () => import('../pages/taji-suspension/taji-suspension.module').then(m => m.TajiSuspensionModule) },
  ],
};

export default defaultAuthRoute;
