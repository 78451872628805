import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ExpansionData {
  percent: number;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExpansionService {
  constructor(
  ) {

  }
  percent = 0;
  text = '';
  message = new Subject<string>();

  getData(): ExpansionData {
    return { percent: this.percent, text: this.text };
  }
  setData({ percent, text }: ExpansionData): void {
    this.percent = percent;
    this.text = text;
    this.message.next();
  }

}
