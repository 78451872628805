import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SettingsService } from '@service/settings/settings.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'component-corp-title',
  templateUrl: 'corp-title.component.html',
  styleUrls: ['corp-title.component.less']
})
export class CorpTitleComponent implements OnInit {
  corpList: Array<{ id: string; title: string; }> = [];

  constructor(
    public settingsService: SettingsService,
    private message: NzMessageService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.getCorpList();
  }

  /** 获取用户所属企业列表 */
  getCorpList(): void {
    this.httpClient.post('tower/user/corp/list', {}).subscribe( // settings/corp/list
      (res: any) => {
        const { code, msg, data } = res;
        if (code === 200) {
          this.corpList = data;
        } else {
          this.message.error(msg);
          return;
        }
      },
      (error) => {
        this.message.error(error.toString());
      },
      () => { }
    );
  }

  /** 切换企业 */
  changeCrop(id: string): void {
    this.httpClient.post('/settings/corp/taji/switch', { id }).subscribe(
      (res: any) => {
        const { code, msg, data } = res;
        if (code === 200) {
          // this.router.navigateByUrl('/dashboard?tk=' + data);
          window.location.href = '/?tk=' + data;
          this.message.success(msg);
        } else {
          this.message.error(msg);
          return;
        }
      },
      (error) => {
        this.message.error(error.toString());
      },
      () => { }
    );
  }
}
