// key: 前端字段值；value: 后端字段值。
export const SORT_ORDER_MAP: {[key: string]: any} = {
  ascend: 'asc',
  descend: 'desc',
  null: null,
};

// 方案状态
export const PROGRAMME_STATUS = [
  { text: '全部', value: 5 },
  { text: '未提交', value: 1 },
  { text: '待审核', value: 2 },
  { text: '已通过', value: 3 },
  { text: '未通过', value: 4 },
];

// 方案类型
export const PROGRAMME_TYPES = [
  { text: '全部', value: 4 },
  { text: '我创建的', value: 1 },
  { text: '我协作的', value: 2 },
  { text: '下级创建', value: 3 },
];

export const SPLIT_KEY = '~@$@~';
