import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzImageModule } from 'ng-zorro-antd/image';
import { DynamicModule } from 'ng-dynamic-component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

// 指令
import { GetResizeImg } from './pipelines/GetResizeImg';
import { EzNumberPipe } from './pipelines/EzNumberPipe';

// 组件
import { NzHighlightModule } from 'ng-zorro-antd/core/highlight';
import { EzContainerComponent } from './components/ez-container/ez-container.component';
import { HelperListComponent } from './components/helper-list/helper-list.component';
import { NameAvatarComponent } from './components/name-avatar/name-avatar.component';
import { LogListComponent } from './components/log-list/log-list.component';
import { PreviewComponent } from './components/preview/preview.component';
import { LineArrowComponent } from './components/line-arrow/line-arrow.component';
import { Abs } from './pipelines/Abs';

const COMPONENTS_NOT_EXPORTS: any[] = [];

const COMPONENTS: any[] = [
  EzContainerComponent,
  HelperListComponent,
  NameAvatarComponent,
  LogListComponent,
  PreviewComponent,
  LineArrowComponent,
];

const DIRECTIVES: any[] = [];

const PIPELINES: any[] = [
  EzNumberPipe,
  GetResizeImg,
  Abs,
];

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  PortalModule,
  ScrollingModule,
  DragDropModule,
  // DynamicComponentModule,
  DynamicModule,

  NzResizableModule,
  NzButtonModule,
  NzAvatarModule,
  NzMessageModule,
  NzSpinModule,
  NzDropDownModule,
  NzIconModule,
  NzFormModule,
  NzAlertModule,
  NzModalModule,
  NzInputModule,
  NzUploadModule,
  NzTableModule,
  NzDividerModule,
  NzCardModule,
  NzSelectModule,
  NzEmptyModule,
  NzPopconfirmModule,
  NzTreeSelectModule,
  NzStepsModule,
  NzTabsModule,
  NzCheckboxModule,
  NzDrawerModule,
  NzToolTipModule,
  NzHighlightModule,
  NzBadgeModule,
  NzSkeletonModule,
  NzSwitchModule,
  NzImageModule,
  NzInputNumberModule
];

@NgModule({
  declarations: [
    ...PIPELINES,
    ...DIRECTIVES,
    ...COMPONENTS,
    ...COMPONENTS_NOT_EXPORTS,
  ],
  imports: [
    ...MODULES,
  ],
  exports: [
    ...MODULES,
    ...PIPELINES,
    ...DIRECTIVES,
    ...COMPONENTS,
  ],
})
export class SharedModule { }
