<div
  class="container"
>
  <div class="corp">企业</div>
  <span class="corp-content" nz-dropdown [nzDropdownMenu]="menu" style="cursor: pointer;">
    <span> {{ settingsService.corpInfo?.title }}</span>
    <i style="margin-left: 8px;color: rgba(0, 0, 0, 0.25);" nz-icon nzType="down" nzTheme="outline"></i>
  </span>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu nzSelectable style="max-width: 150px;;max-height: 300px; overflow-y: auto; background: #fff;">
      <li
        class="ez-text-ellipsis"
        nz-menu-item
        *ngFor="let item of corpList"
        (click)="changeCrop(item.id)"
      >{{ item.title }}</li>
    </ul>
  </nz-dropdown-menu>
</div>
