import { Pipe, PipeTransform } from '@angular/core';
import {formatNumber, formatPercent} from '@angular/common';

@Pipe({ name: 'ezNumber' })
export class EzNumberPipe implements PipeTransform {
  constructor(
  ) {}

  transform(
    num: any,
    format: string = 'number',
    digitsInfo: string = '1.0-2',
  ): any {
    format = format.toLowerCase();
    if (format === 'date' || format === 'string') {
      return num || '-';
    }

    if ( num === null || !isFinite(num) ) {
      return '-';
    }

    if (format === null || format === undefined) {
      format = 'number';
    }

    if (format === 'percent') {
      return formatPercent(num, 'zh-CN', digitsInfo);
    }

    if (format === 'thousand') {
      return num < 1000 ?
        formatNumber(num, 'zh-CN', digitsInfo) :
        `${formatNumber(num / 1000, 'zh-CN', digitsInfo)}k`;
    }

    return formatNumber(num, 'zh-CN', digitsInfo);
  }

}
