import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenService } from '@service/token.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.scss']
})
export class FirstLoginComponent implements OnInit {
  validateForm!: FormGroup;

  @Input() close$ = new Subject<void>();

  loading = false;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private message: NzMessageService,
  ) { }

  submitForm(): void {
    // tslint:disable-next-line: forin
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) {
      return;
    }
    this.loading = true;
    const { password, checkPassword: rePassword } = this.validateForm.getRawValue();
    const param = { password, rePassword };
    this.http.post('tower/user/taji/password/modify', param).subscribe(
      (res: any) => {
        if (res.code === 200) {
          this.message.success('修改成功');
          this.close$.next();
          window.location.reload();
        } else {
          this.message.success(res.msg);
        }
      }, error => { }, () => {
        this.loading = false;
      }
    );
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      password: [null, [Validators.required, Validators.pattern(/^.{6,}$/)]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
    });
  }

}
