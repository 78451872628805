import { NgModule } from '@angular/core';
import { RouteRoutingModule } from './routes-routing.module';

@NgModule({
  declarations: [],
  imports: [
    RouteRoutingModule,
  ]
})
export class RoutesModule { }
