import { NzMessageService } from 'ng-zorro-antd/message';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpResponseBase, HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, Observer, of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { IStore, ITokenModel, ITokenService } from '../../service/interface';
import { _HttpClient } from '../../service/http/http.client';
import { EZ_SERVICE_TOKEN, TokenService } from '../../service/token.service';
import { environment } from '../../../environments/environment';
import { EzAuthConfig } from '../../service/auth.config';
import { CheckSimple, ToLogin } from '../../service/token/helper';
import { SimpleTokenModel } from '../../service/token/simple/simple.model';
import { EZ_STORE_TOKEN } from '../../service/local-storage.service';
// import { URLSearchParams } from 'whatwg-url';

class HttpAuthInterceptorHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) { }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.interceptor.intercept(req, this.next);
  }
}

const CODEMESSAGE = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable({
  providedIn: 'root'
})
export class DefaultInterceptor implements HttpInterceptor {
  private model: ITokenModel = { token: '' };

  constructor(
    private injector: Injector,
    private authConfig: EzAuthConfig,
    @Inject(EZ_STORE_TOKEN) private store: IStore,
    private msg: NzMessageService,
  ) { }

  // private get notification(): NzNotificationService {
  //   return this.injector.get(NzNotificationService);
  // }

  private goTo(url: string): void {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase): void {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }

    // const errortext = CODEMESSAGE[ev.status] || ev.statusText;
    // this.notification.error(`请求错误 ${ev.status}: ${ev.url}`, errortext);
  }

  private handleData(ev: HttpResponseBase): Observable<any> {
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    if (ev.status > 0) {
      this.injector.get(_HttpClient).end();
    }

    this.checkStatus(ev);
    // 业务处理：一些通用操作
    switch (ev.status) {
      case 200:
        // 业务层级错误处理，以下是假定restful有一套统一输出格式（指不管成功与否都有相应的数据格式）情况下进行处理
        // 例如响应内容：
        //  错误内容：{ status: 1, msg: '非法参数' }
        //  正确内容：{ status: 0, response: {  } }
        // 则以下代码片断可直接适用
        // if (event instanceof HttpResponse) {
        //     const body: any = event.body;
        //     if (body && body.status !== 0) {
        //         this.msg.error(body.msg);
        //         // 继续抛出错误中断后续所有 Pipe、subscribe 操作，因此：
        //         // this.http.get('/').subscribe() 并不会触发
        //         return throwError({});
        //     } else {
        //         // 重新修改 `body` 内容为 `response` 内容，对于绝大多数场景已经无须再关心业务状态码
        //         return of(new HttpResponse(Object.assign(event, { body: body.response })));
        //         // 或者依然保持完整的格式
        //         return of(event);
        //     }
        // }
        break;
      case 401:
        // this.notification.error(`未登录或登录已过期，请重新登录。`, ``);
        // 清空 token 信息
        // (this.injector.get(EZ_SERVICE_TOKEN) as ITokenService).clear();
        console.log('go to auth/login');
        const search = window.location.href.substring(window.location.href.indexOf('?'));
        const url = new URLSearchParams(search);
        const redirect = url.get('redirect') || window.encodeURIComponent(window.location.href);
        this.goTo(`/auth/login?redirect=${redirect}`);
        break;
      case 460:
        console.log('refresh token');
        const token = ev.headers.get('token');
        if (token) {
          const tkModel = this.store.get(this.authConfig.storeKey);
          tkModel.token = token;
          this.store.set(this.authConfig.storeKey, tkModel);
        }
        break;
      case 403:
      case 404:
      case 500:
        // this.goTo(`/exception/${ev.status}`);
        console.log(ev);
        this.msg.error((ev as any)?.error?.msg || '出错了');
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn('未可知错误，大部分是由于后端不支持CORS或无效配置引起', ev);
          return throwError(ev);
        }
        break;
    }
    return of(ev);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    const url = req.url.indexOf('http://') > -1 ? req.url : environment.SERVER_URL + req.url;
    // if (!url.startsWith('https://') && !url.startsWith('http://')) {
    //   url = environment.SERVER_URL + url;
    // }

    req = req.clone({ url });
    // req.headers.append('Content-Type', 'application/json');

    // console.log('intercept:', environment.SERVER_URL, req);

    const options = this.authConfig;
    if (options.ignores) {
      for (const item of options.ignores as RegExp[]) {
        if (item.test(req.url) && req.url.indexOf('/passport/logout') === -1 && req.url.indexOf('/passport/password/modify') === -1) {
          return next.handle(req);
        }
      }
    }
    if (
      options.allowAnonymousKey &&
      (req.params.has(options.allowAnonymousKey) || new RegExp(`[\?|&]${options.allowAnonymousKey}=[^&]+`).test(req.urlWithParams))
    ) {
      return next.handle(req);
    }

    if (this.isAuth()) {
      req = this.setReq(req);
    } else {
      // console.log('to login');
      ToLogin(options, this.injector);
      // Interrupt Http request, so need to generate a new Observable
      const err$ = new Observable((observer: Observer<HttpEvent<any>>) => {
        const res = new HttpErrorResponse({
          url: req.url,
          headers: req.headers,
          status: 401,
          statusText: `来自 ezcel/auth 的拦截，所请求URL未授权，若是登录API可加入 [url?_allow_anonymous=true] 来表示忽略校验`,
        });
        observer.error(res);
      });
      if (options.executeOtherInterceptors) {
        const interceptors = this.injector.get(HTTP_INTERCEPTORS, []);
        const lastInterceptors = interceptors.slice(interceptors.indexOf(this) + 1);
        if (lastInterceptors.length > 0) {
          // tslint:disable-next-line:variable-name
          const chain = lastInterceptors.reduceRight((_next, _interceptor) => new HttpAuthInterceptorHandler(_next, _interceptor), {
            handle: (_: HttpRequest<any>) => err$,
          });
          return chain.handle(req);
        }
      }
      return err$;
    }

    // console.log('next handle');

    return next.handle(req).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理
        if (event instanceof HttpResponseBase) {
          return this.handleData(event);
        }
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err)),
    );
  }

  setReq(req: HttpRequest<any>): HttpRequest<any> {
    const { tokenSendTemplate, tokenSendKey, tokenSendPlace } = this.authConfig;
    const token = tokenSendTemplate.replace(/\$\{([\w]+)\}/g, (_: string, g) => this.model[g]);
    switch (tokenSendPlace) {
      case 'header':
        const obj: any = {};
        obj[tokenSendKey] = token;
        req = req.clone({
          setHeaders: obj,
        });
        break;
      case 'body':
        const body = req.body || {};
        body[tokenSendKey] = token;
        req = req.clone({
          body,
        });
        break;
      case 'url':
        req = req.clone({
          params: req.params.append(tokenSendKey, token),
        });
        break;
    }
    return req;
  }

  isAuth(): boolean {
    this.model = this.injector.get(EZ_SERVICE_TOKEN).get() as SimpleTokenModel;
    return CheckSimple(this.model as SimpleTokenModel);
  }
}
