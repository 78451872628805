import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { ResetComponent } from './reset/reset.component';
import { LayoutLogoContainerComponent } from './components/layout-logo-container/layout-logo-container.component';
import { LoginContainerComponent } from './components/login-container/login-container.component';
@NgModule({
  declarations: [
    LoginComponent,
    ResetComponent,
    LayoutLogoContainerComponent,
    LoginContainerComponent,
  ],
  imports: [
    SharedModule,
    AuthRoutingModule,
  ]
})
export class AuthModule { }
