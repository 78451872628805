import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { ExpansionService } from './expansion.service';
import {HttpClient} from '@angular/common/http';
import {EZ_SERVICE_TOKEN} from '../../../../service/token.service';
import {Router} from '@angular/router';
import {ITokenService} from '../../../../service/interface';
import { NzMessageService } from 'ng-zorro-antd/message';




@Component({
  selector: 'app-data-expansion',
  templateUrl: 'expansion.component.html',
  styleUrls: ['expansion.component.less']
})
export class ExpansionComponent implements OnInit, OnDestroy {

  data = {
    text: '',
    percent: 0,
  };
  link = '#';
  renderSub: any;

  constructor(
    private expansionService: ExpansionService,
    private httpClient: HttpClient,
    private message: NzMessageService,
    private router: Router,
    @Inject(EZ_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {}

  ngOnInit(): void  {
    this.data = this.expansionService.getData();
    this.renderSub = this.expansionService.message.subscribe({
      next: () => {
        this.data = this.expansionService.getData();
      }
    });
  }
  ngOnDestroy(): void {
    if (this.renderSub) {
      this.renderSub.unsubscribe();
      this.renderSub = null;
    }
  }

  logout(): void {
    this.httpClient.post('passport/logout', null).subscribe(
      (res: any) => {
        const { code, msg, data } = res;
        if (code !== 200) {
          this.message.error(msg);
          return;
        }
        this.tokenService.clear();
        localStorage.clear();
        // this.aclService.setFull(false);
        this.router.navigateByUrl(this.tokenService.login_url as string);
      },
      error => {
        this.message.warning(error.toString());
      }
    );
  }
}
