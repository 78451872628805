import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { ITokenService } from '../../../service/interface';
import { StartupService } from '../../../core';
import { _HttpClient } from '../../../service/http/http.client';
import { EZ_SERVICE_TOKEN } from '../../../service/token.service';

function pasValidators(params: any) {
  if (params.parent) {
    if (params.parent.value.password !== params.value) {
      return {
        rePwdValid: true
      }
    }

  }
  return null;
}

@Component({
  selector: 'app-reset',
  templateUrl: 'reset.component.html',
  styleUrls: ['reset.component.less']
})
export class ResetComponent {

  error = '';

  forgetForm: FormGroup;

  count = 0;
  interval$: any;

  forgetConfirmLoading = false;

  forgetLoading = false;


  constructor(
    fb: FormBuilder,
    modalSrv: NzModalService,
    private router: Router,
    @Inject(EZ_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    public http: _HttpClient,
    public msg: NzMessageService
  ) {
    this.forgetForm = fb.group({
      mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      captcha: [null, [Validators.required]],
      password: [null, Validators.required],
      confirm: [null, this.confirmValidator],
      remember: [true],
    });
    modalSrv.closeAll();
  }

  forgetSubmit(): void {
    // tslint:disable-next-line: forin
    for (const i in this.forgetForm.controls) {
      this.forgetForm.controls[i].markAsDirty();
      this.forgetForm.controls[i].updateValueAndValidity();
    }
    if (!this.forgetForm.invalid) {
      this.forgetConfirmLoading = true;
      const { mobile, password, captcha } = this.forgetForm.controls;
      const param = {
        mobile: mobile.value,
        password: password.value,
        captcha: captcha.value
      };
      this.http
        .post('passport/password/reset?_allow_anonymous=true', param)
        .subscribe((res: any) => {
          if (res.code !== 200) {
            this.error = res.msg;
            return;
          }
          this.msg.success('设置成功');
          this.error = '';
          setTimeout(() => {
            this.login(mobile.value, password.value);
          }, 1000);
        }, () => { }, () => { this.forgetConfirmLoading = false; });
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.forgetForm.controls.confirm.updateValueAndValidity());
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.forgetForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

  login(username: string, password: string): void {
    this.http
      .post('passport/taji/login/account?_allow_anonymous=true', { username, password })
      .subscribe((res: any) => {
        if (res.code !== 200) {
          this.error = res.msg;
          return;
        }
        // 设置用户Token信息
        this.tokenService.set(res.data);
        // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
        this.startupSrv.load().then(() => {
          let url = this.tokenService.referrer?.url || '/';
          if (url.includes('/passport')) {
            url = '/';
          }
          this.router.navigateByUrl(url);
        });
      });
  }

  getCaptcha(flag = 'mobile'): void {
    let param;
    if (this.forgetForm.controls.mobile.invalid) {
      this.forgetForm.controls.mobile.markAsDirty({ onlySelf: true });
      this.forgetForm.controls.mobile.updateValueAndValidity({ onlySelf: true });
      return;
    }
    param = this.forgetForm.controls.mobile.value;


    this.http.post('/passport/sms/login/code', param).subscribe((res) => {
      if (res.code === 200) {
        this.count = 59;
        this.interval$ = setInterval(() => {
          this.count -= 1;
          if (this.count <= 0) {
            clearInterval(this.interval$);
          }
        }, 1000);
      } else {
        this.error = res.msg;
      }
    }, () => { }, () => { this.forgetLoading = false; });
  }

}
