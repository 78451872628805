import {inject, Inject, InjectionToken} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import {AuthReferrer, IStore, ITokenModel, ITokenService} from './interface';
import {EzAuthConfig} from './auth.config';
import {EZ_STORE_TOKEN} from './local-storage.service';

export function EZ_SERVICE_TOKEN_FACTORY(): ITokenService {
  return new TokenService(inject(EzAuthConfig), inject(EZ_STORE_TOKEN));
}

export const EZ_SERVICE_TOKEN = new InjectionToken<ITokenService>('EZ_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: EZ_SERVICE_TOKEN_FACTORY,
});

export class TokenService implements ITokenService {
  private change$ = new BehaviorSubject<ITokenModel | null>(null);
  private _referrer: AuthReferrer = {};

  constructor(private options: EzAuthConfig, @Inject(EZ_STORE_TOKEN) private store: IStore) {}

  get login_url(): string {
    return this.options.loginUrl;
  }

  get referrer(): AuthReferrer {
    return this._referrer;
  }

  set(data: ITokenModel): boolean {
    this.change$.next(data);
    return this.store.set(this.options.storeKey, data);
  }

  // get(type?: any);
  get<T extends ITokenModel>(type?: new () => T): T {
    const data = this.store.get(this.options.storeKey);
    return type ? (Object.assign(new type(), data) as T) : (data as T);
  }

  clear(): void {
    this.change$.next(null);
    this.store.remove(this.options.storeKey);
  }

  change(): Observable<ITokenModel | null> {
    return this.change$.pipe(share());
  }
}
