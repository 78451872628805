import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginContainerComponent } from './components/login-container/login-container.component';
import { LoginComponent } from './login/login.component';
import { ResetComponent } from './reset/reset.component';

const routes: Routes = [
  {
    path: '',
    component: LoginContainerComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { title: '登录' },
      },
      {
        path: 'reset',
        component: ResetComponent,
        data: { title: '重置密码' },
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
