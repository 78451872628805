<div *ngIf="!settingsService.isFullScreen" class="height-p-100" [ngClass]="{ help: displayHelp }">
  <div class="justify-content-between header" role="banner">
    <div class="d-flex align-items-center height-p-100">
      <div class="logo-warp" routerLink='' title="回首页">
        <div class="img-wrap">
          <img src="./assets/images/logo.png" alt="">
        </div>
        <span>塔机方案系统</span>
      </div>
      <component-corp-title class="mg-l-20"></component-corp-title>
    </div>
    <div>
      <ul>
        <li class="d-flex">
          <div class="mg-l-12 user-container" nz-dropdown nzPlacement="bottomRight" [nzDropdownMenu]="menuTpl">
            <span>{{ settingsService.corpName }}</span>
            <nz-avatar nzSrc='./assets/images/defaultUserImage.png' style="margin-left: 16px;"></nz-avatar>
            <!-- <component-name-avatar
              [name]="settingsService.userInfo?.nickName || ''"
              [width]="32"
              style="margin-left: 16px;"
            ></component-name-avatar> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<nz-dropdown-menu #menuTpl="nzDropdownMenu">
  <div nz-menu class="width-sm">
    <a routerLink="/console/setting/account/info">
      <div nz-menu-item>
        <i nz-icon nzType="user" class="mr-sm"></i>
        个人设置
      </div>
    </a>
    <li nz-menu-divider></li>
    <div nz-menu-item (click)="logout()">
      <i nz-icon nzType="logout" class="mr-sm"></i>
      退出登录
    </div>
  </div>
</nz-dropdown-menu>