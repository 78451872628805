<form nz-form [formGroup]="form" (ngSubmit)="submit()">
  <nz-tabset [nzAnimated]="false" class="tabs"  (nzSelectChange)="switch($event)">
    <nz-tab [nzTitle]="'密码登录'">
      <nz-alert *ngIf="error" [nzType]="'error'" [nzMessage]="error" [nzShowIcon]="true" class="mg-b-24">
      </nz-alert>
      <nz-form-item>
        <nz-form-control nzErrorTip="请输入你的手机号">
          <nz-input-group nzSize="large" nzPrefixIcon="user">
            <input nz-input formControlName="username" placeholder="你的手机号" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="请输入你的密码">
          <nz-input-group nzSize="large" nzPrefixIcon="lock">
            <input nz-input type="password" formControlName="password" placeholder="密码" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </nz-tab>
    <nz-tab [nzTitle]="'手机号登录'">
      <nz-alert *ngIf="error" [nzType]="'error'" [nzMessage]="error" [nzShowIcon]="true" class="mg-b-24">
      </nz-alert>
      <nz-form-item>
        <nz-form-control [nzErrorTip]="mobileErrorTip">
          <nz-input-group nzSize="large" nzPrefixIcon="mobile">
            <input nz-input formControlName="mobile" placeholder="你的手机号" />
          </nz-input-group>
          <ng-template #mobileErrorTip let-i>
            <ng-container *ngIf="i.errors&&i.errors.required">
              请输入手机号！
            </ng-container>
            <ng-container *ngIf="i.errors&&i.errors.pattern">
              手机号格式错误！
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzErrorTip]="'请输入验证码！'">
          <nz-row [nzGutter]="8">
            <nz-col [nzSpan]="16">
              <nz-input-group nzSize="large" nzPrefixIcon="mail">
                <input nz-input formControlName="captcha" placeholder="验证码" />
              </nz-input-group>
            </nz-col>
            <!-- [disabled]="form.get('mobile').errors||count>0" -->
            <nz-col [nzSpan]="8">
              <button type="button"
                [ngStyle]="{'color':!!form.get('mobile')!.errors || count>0 ? '' : '#6489FF' }" nz-button
                nzSize="large" (click)="getCaptcha()" [disabled]="!!form.get('mobile')!.errors || count>0"
                nzBlock [nzLoading]="http.loading">
                {{ count ? (count + 's') : ('获取验证码') }}
              </button>
            </nz-col>
          </nz-row>
        </nz-form-control>
      </nz-form-item>
    </nz-tab>
  </nz-tabset>
  <nz-form-item>
    <nz-col [nzSpan]="12">
      <label nz-checkbox formControlName="remember">自动登录</label>
    </nz-col>
    <nz-col [nzSpan]="12" class="text-right">
      <a class="forgot" routerLink='/auth/reset'>忘记密码</a>
    </nz-col>
  </nz-form-item>
  <nz-form-item>
    <button class="button-color" nz-button type="submit" nzType="primary" nzSize="large"
      [nzLoading]="http.loading" nzBlock>登录</button>
  </nz-form-item>
</form>