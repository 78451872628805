<div
  class="position-relative name-avatar"
  [ngStyle]="{
    'width': width + 'px',
    'height': width + 'px',
    'line-height': width + 'px',
    'font-size': fontSize + 'px'
  }"
>
  <span>{{name?.substr(0, 1)}}</span>
</div>
