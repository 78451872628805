import { Component, Input, OnInit } from '@angular/core';
import { cos, sin } from '@app/utils/utils';

@Component({
  selector: 'component-line-arrow',
  templateUrl: 'line-arrow.component.html',
})
export class LineArrowComponent implements OnInit {
  @Input() lineWidth = 1;
  @Input() borderHeight = 1;
  @Input() color = '#333';
  @Input() borderStyle: 'solid' | 'dashed' = 'solid';
  @Input() angle = 0;

  matrix = '1, 0, 0, 1, 0, 0';

  ngOnInit(): void {
    this.setMatrix(this.angle);
  }

  setMatrix(angle: number, x = 0, y = 0): void {
    this.matrix = `${cos(angle)}, ${sin(angle)}, ${-sin(angle)}, ${cos(angle)}, ${x}, ${y}`;
  }
}
