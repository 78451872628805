<div #containerRef class="d-flex align-items-center">
  <div
    *ngIf="list && list.length;"
    class="d-flex align-items-center flex-wrap list-wrapper"
    nz-dropdown
    [nzDropdownMenu]="menuView"
    nzTrigger="click"
    [nzClickHide]="false"
    (nzVisibleChange)="changeViewVisible($event)"
  >
    <ng-container *ngFor="let item of list">
      <component-name-avatar style="margin: 2px 2px 2px 0;" *ngIf="avatarType === 'familyName'; else fullNameTpl" [name]="item.name"></component-name-avatar>
      <ng-template #fullNameTpl>
        <span *ngIf="item.show" class="helper-name">{{ item.name }}</span>
      </ng-template>
    </ng-container>
    <span *ngIf="moreNum > 0" class="helper-name more-num">+{{moreNum}}</span>
  </div>
  <i
    *ngIf="showAdd || isAlwaysShowAdd"
    [hidden]="!isHover && !isAlwaysShowAdd"
    class="icon-add"
    nz-icon
    nzIconfont="icontianjia"
    nz-dropdown
    [nzDropdownMenu]="menuAdd"
    nzTrigger="click"
    [nzClickHide]="false"
    (click)="getList()"
    [(nzVisible)]="addMenuVisible"
    (nzVisibleChange)="changeAddVisibe($event, true)"
  ></i>
</div>
<nz-dropdown-menu #menuView="nzDropdownMenu">
  <ul class="helper-drop-down" nz-menu>
    <ng-container *ngIf="list && list.length;else emptyTpl">
      <li class="d-flex align-items-center justify-content-between drop-down-item" *ngFor="let item of list; index as index" nz-menu-item>
        <div class="flex-1 d-flex align-items-center overflow-hidden">
          <component-name-avatar [name]="item.name"></component-name-avatar>
          <span class="pd-l-10 text-ellipsis name">{{item.name}}</span>
        </div>
        <i
          *ngIf="type === 1;"
          class="icon-delete"
          nz-icon
          nzIconfont="icondelete1"
          nz-popconfirm
          nzPopconfirmTitle="是否确认删除？"
          (nzOnConfirm)="deleteHelper(index)"
        ></i>
        <span *ngIf="type !== 1 && item.owner === 1" class="text-self">我</span>
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>
<nz-dropdown-menu #menuAdd="nzDropdownMenu">
  <div nz-menu>
    <div *ngIf="!loadingList" class="helper-search-wrapper">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input
          type="text"
          nz-input
          placeholder="请输入姓名"
          [(ngModel)]="searchValue"
          (ngModelChange)="searchValueChange($event)"
        />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <ng-template #suffixIconSearch>
          <i *ngIf="!searchValue;else clearTpl" class="icon-search" nz-icon nzType="search"></i>
          <ng-template #clearTpl>
            <i class="ant-input-clear-icon" nz-icon nzTheme="fill" nzType="close-circle" (click)="clearSearch()"></i>
          </ng-template>
        </ng-template>
      </ng-template>
    </div>
    <ul class="overflow-y__auto helper-drop-down">
      <nz-skeleton
        class="skeleton"
        [ngClass]="{'skeleton-loading': loadingList}"
        [nzLoading]="loadingList"
        [nzActive]="true"
        [nzTitle]="false"
        [nzParagraph]="{ rows: 4, width: '100%' }"
      >
        <ng-container *ngIf="dropdownHelperList && dropdownHelperList.length;else emptyTpl">
          <li style="height: 48px;" class="d-flex align-items-center justify-content-between drop-down-item" *ngFor="let item of dropdownHelperList" nz-menu-item (click)="onChange(item)">
            <div class="flex-1 d-flex align-items-center overflow-hidden">
              <component-name-avatar [name]="item.name"></component-name-avatar>
              <span
                class="pd-l-10 text-ellipsis name"
                [innerHTML]="item.name | nzHighlight: searchValue: 'i': 'font-highlight'"
              ></span>
            </div>
            <i *ngIf="item.selected" class="icon-selected" nz-icon nzIconfont="iconxuanzhong1"></i>
          </li>
        </ng-container>
      </nz-skeleton>
    </ul>
  </div>
</nz-dropdown-menu>
<ng-template #emptyTpl>
  <nz-empty></nz-empty>
</ng-template>
