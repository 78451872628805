import {IStore, ITokenModel} from './interface';
import {InjectionToken} from '@angular/core';

export function EZ_STORE_TOKEN_LOCAL_FACTORY(): IStore {
  return new LocalStorageStore();
}

export const EZ_STORE_TOKEN = new InjectionToken<IStore>('AUTH_STORE_TOKEN', {
  providedIn: 'root',
  factory: EZ_STORE_TOKEN_LOCAL_FACTORY,
});

export class LocalStorageStore implements IStore {
  get(key: string): ITokenModel {
    return JSON.parse(localStorage.getItem(key) || '{}') || {};
  }

  set(key: string, value: ITokenModel | null): boolean {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
