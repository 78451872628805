import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { LayoutModule } from './layout/layout.module';
import { AuthModule } from './pages/auth/auth.module';
import { RouteRoutingModule } from './routes/routes-routing.module';
import { RoutesModule } from './routes/routes.module';
import { CustomPreloadingStrategy } from './service/custom-preloading-strategy.service';
import { DefaultInterceptor, StartupService } from '@core';
// import { NzLayoutModule } from 'ng-zorro-antd/layout';
// import { NzMenuModule } from 'ng-zorro-antd/menu';

export function StartupServiceFactory(startupService: StartupService): () => Promise<any> {
  const url = window.location.href;
  const exclusions = ['/auth/login'];
  if (exclusions.some( u => url.indexOf(u) >= 0)) {
    return () => startupService.loadNotLogin();
  }
  return () => startupService.load();
}
const APPINIT_PROVIDES = [
  StartupService,
  {
    provide: APP_INITIALIZER,
    useFactory: StartupServiceFactory,
    deps: [StartupService],
    multi: true
  }
];

const LANG = {
  currencyCode: 'CNY',
  abbr: 'zh'
};

const INTERCEPTOR_PROVIDES = [
  { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
  { provide: DEFAULT_CURRENCY_CODE, useValue: LANG.currencyCode },
  { provide: LOCALE_ID, useValue: LANG.abbr },
];

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    LayoutModule,
    RouteRoutingModule,
    RoutesModule,
    AuthModule,
    // NzLayoutModule,
    // NzMenuModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    CustomPreloadingStrategy,
    ...INTERCEPTOR_PROVIDES,
    ...APPINIT_PROVIDES,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
