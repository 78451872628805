import { Injectable } from '@angular/core';
import {environment} from '@env/environment';

@Injectable({ providedIn: 'root' })
export class EzAuthConfig {
  /**
   * 存储KEY值
   */
  storeKey = '_token';
  /**
   * 无效时跳转至登录页，包括：
   * - 无效token值
   * - token已过期（限JWT）
   */
  tokenInvalidRedirect = true;
  /**
   * token过期时间偏移值，默认：`10` 秒（单位：秒）
   */
  tokenExpOffset = 10;
  /**
   * 发送token参数名，默认：token
   */
  tokenSendKey = 'token';
  /**
   * 发送token模板（默认为：`${token}`），使用 `${token}` 表示token点位符，例如：
   *
   * - `Bearer ${token}`
   */
  // tslint:disable-next-line:no-invalid-template-strings
  tokenSendTemplate = '${token}';
  /**
   * 发送token参数位置，默认：header
   */
  tokenSendPlace?: 'header' | 'body' | 'url' = 'header';
  /**
   * 登录页路由地址
   */
  loginUrl = '/auth/login';
  /**
   * 忽略TOKEN的URL地址列表，默认值为：[ /\/login/, /assets\//, /passport\// ]
   */
  ignores?: RegExp[] | null = [/\/login/, /assets\//, /passport\//, /report\//];
  /**
   * 允许匿名登录KEY，若请求参数中带有该KEY表示忽略TOKEN
   */
  allowAnonymousKey = `_allow_anonymous`;
  /**
   * 是否校验失效时命中后继续调用后续拦截器的 `intercept` 方法，默认：`true`
   */
  executeOtherInterceptors = true;
}
