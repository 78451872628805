<div class="padding">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="password"  nzRequired>设置密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入密码,不少于6位">
        <input nz-input type="password" id="password" formControlName="password"
        placeholder="请输入密码"
          (ngModelChange)="updateConfirmValidator()" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="checkPassword" nzRequired>确认密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
        <input nz-input type="password" placeholder="请再次输入密码" formControlName="checkPassword" id="checkPassword" />
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            请确认密码
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            两次输入的密码不同
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
<div class="footer d-flex justify-content-between align-items-center" >
  <span style="color: #F5222D;">当前账号密码为空，请设置密码。</span>
  <span>
    <button nz-button nzType="default" style="margin-right: 10px;" (click)="close$.next()">取消</button>
    <button nz-button nzType="primary" [nzLoading]='loading' (click)="submitForm()">确定</button>
  </span>
</div>
