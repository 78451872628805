import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { LayoutFullScreenComponent } from './fullscreen/fullscreen.component';
import { LayoutDefaultComponent } from './defaullt/default.component';
import { SidebarComponent } from './defaullt/sidebar/sidebar.component';
import { SidebarNavComponent } from './defaullt/sidebar/nav-bar/nav-bar.component';
import { ExpansionComponent } from './defaullt/sidebar/expansion/expansion.component';
import { HeaderComponent } from './components/header/header.component';
import { CorpTitleComponent } from './components/corp-title/corp-title.component';

@NgModule({
  declarations: [
    LayoutFullScreenComponent,
    HeaderComponent,
    LayoutDefaultComponent,
    SidebarComponent,
    SidebarNavComponent,
    ExpansionComponent,
    CorpTitleComponent,
  ],
  imports: [
    SharedModule,
  ]
})
export class LayoutModule {}
