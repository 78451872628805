<div class="ez-flex ez-flex-column wrapper">
  <div id="logo_container_top" class="ez-flex ez-flex-column ez-flex-center ez-flex-middle top-box">
    <!-- <img class="logo" [src]="'YI-BI.png' | getAssetsImage"> -->
  </div>
  <div class="ez-overflow-y__auto bottom-box">
    <div class="container">
      <ng-content></ng-content>
    </div>
  </div>
</div>
