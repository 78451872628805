import {
  Component, Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SettingsService } from '../../service/settings/settings.service';

@Component({
  selector: 'app-layout-default',
  templateUrl: './default.component.html',
  styleUrls: ['default.component.less'],
})
export class LayoutDefaultComponent implements OnDestroy, OnInit {
  private unsubscribe$ = new Subject<void>();
  isFetching = false;
  displayHelp = false;
  corpList = [];

  constructor(
    public settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    const { unsubscribe$ } = this;
    unsubscribe$.next();
    unsubscribe$.complete();
  }

}
