import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { ITokenService } from '../../../service/interface';
import { StartupService } from '../../../core';
import { _HttpClient } from '../../../service/http/http.client';
import { EZ_SERVICE_TOKEN } from '../../../service/token.service';
import { HomeService } from '@app/pages/home/home.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit, OnDestroy {
  links = [
    {
      title: '帮助',
      href: '',
    },
    {
      title: '隐私',
      href: '',
    },
    {
      title: '条款',
      href: '',
    },
  ];

  error = '';
  type = 0;
  form!: FormGroup;
  forgetForm!: FormGroup;
  count = 0;
  interval$: any;
  forgetInterval$: any;

  isForget = false;

  forgetLoading = false;

  forgetConfirmLoading = false;

  constructor(
    private fb: FormBuilder,
    private modalSrv: NzModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(EZ_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    public http: _HttpClient,
    public msg: NzMessageService,
    public homeService: HomeService,
  ) {
    this.form = this.fb.group({
      username: [null, [Validators.required]], // [null, [Validators.required, Validators.minLength(4)]],
      password: [null, Validators.required],
      mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      captcha: [null, [Validators.required]],
      remember: [true],
    });

    this.modalSrv.closeAll();
  }

  get username(): AbstractControl {
    return this.form.controls.username;
  }
  get password(): AbstractControl {
    return this.form.controls.password;
  }
  get mobile(): AbstractControl {
    return this.form.controls.mobile;
  }
  get captcha(): AbstractControl {
    return this.form.controls.captcha;
  }

  ngOnInit(): void {

  }

  submit(): void {
    this.error = '';
    // let param = {
    //   userName: '',
    //   password: '',
    //   mobile: '',
    //   captcha: ''
    // };

    let url = '';
    let param = null;
    if (this.type === 0) {
      this.username.markAsDirty();
      this.username.updateValueAndValidity();
      this.password.markAsDirty();
      this.password.updateValueAndValidity();
      if (this.username.invalid || this.password.invalid) {
        return;
      }
      // param.userName = this.userName.value;
      // param.password = this.password.value;

      url = '/passport/taji/login/account?_allow_anonymous=true';
      param = {
        username: this.username.value,
        password: this.password.value
      };
    } else {
      this.mobile.markAsDirty();
      this.mobile.updateValueAndValidity();
      this.captcha.markAsDirty();
      this.captcha.updateValueAndValidity();
      if (this.mobile.invalid || this.captcha.invalid) {
        return;
      }
      url = '/passport/taji/login/mobile?_allow_anonymous=true';
      param = {
        mobile: this.mobile.value,
        captcha: this.captcha.value
      };
    }

    // 默认配置中对所有HTTP请求都会强制 [校验](https://ng-alain.com/auth/getting-started) 用户 Token
    // 然一般来说登录请求不需要校验，因此可以在请求URL加上：`/login?_allow_anonymous=true` 表示不触发用户 Token 校验
    this.http
      .post(url, param)
      .subscribe((res: any) => {
        if (res.code !== 200) {
          this.error = res.msg;
          return;
        }
        // 设置用户Token信息
        this.tokenService.set(res.data);
        // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
        this.startupSrv.load().then(() => {
          // tslint:disable-next-line: no-non-null-assertion
          let ref = this.tokenService.referrer!.url || '/';
          if (ref.includes('/passport')) {
            ref = '/';
          }
          // if (res.data.noPassword) {
          //   ref = '/auth/set-password';
          //   const { r } = this.activatedRoute.snapshot.queryParams;
          //   r ? window.location.href = r : this.router.navigateByUrl(ref);
          // } else {
          if (res.data.noPassword) {
            this.homeService.setNeedPassword();
          }
          const { redirect } = this.activatedRoute.snapshot.queryParams;
          console.log('redirect', redirect, ref, res.data.roleId === 4, res);
          redirect ? window.location.href = redirect :
            res.data.roleId === 4 ? this.router.navigateByUrl('project') : this.router.navigateByUrl(ref);
          // }
        });
      });
  }


  switch(ret: any): void {
    this.error = '';
    this.type = ret.index;
  }



  getCaptcha(flag = 'mobile'): void {
    if (this.mobile.invalid) {
      this.mobile.markAsDirty({ onlySelf: true });
      this.mobile.updateValueAndValidity({ onlySelf: true });
      return;
    }
    let param;
    param = this.mobile.value;

    this.http.post('/passport/sms/login/code', param).subscribe((res) => {
      if (res.code === 200) {
        this.count = 59;
        this.interval$ = setInterval(() => {
          this.count -= 1;
          if (this.count <= 0) {
            clearInterval(this.interval$);
          }
        }, 1000);
      } else {
        this.error = res.msg;
      }
    }, () => { }, () => { });
  }

  back(): void {

  }

  ngOnDestroy(): void {
    if (this.interval$) {
      clearInterval(this.interval$);
    }
  }

}
