import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IHelper, TProgrammeType } from './types';

@Component({
  selector: 'component-helper-list',
  templateUrl: 'helper-list.component.html',
  styleUrls: ['helper-list.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: HelperListComponent,
    multi: true
  }]
})
export class HelperListComponent implements OnInit, OnDestroy {
  @ViewChild('containerRef', { static: false }) containerRef: ElementRef | null = null;
  // 方案 id
  @Input() id: string | null = null;
  initList: IHelper[] = [];
  list: IHelper[] = [];
  // 是否自己创建，详见 TProgrammeType
  @Input() type: TProgrammeType = 2;
  // 不通过 hover 展示，总是显示添加 icon
  @Input() isAlwaysShowAdd = false;
  // list 展示形式是全称还是姓氏
  @Input() avatarType: 'fullName' | 'familyName' = 'fullName';

  // 是否展示添加 icon，如果 ture 通过 hover 展示，并且该组件需在 table 表格中
  showAdd = false;

  isHover = false;
  moreNum = 0;
  addMenuVisible = false;
  tr: HTMLElement | null = null;
  timer: any = null;
  loadingList = false;
  allHelperList: IHelper[] = [];
  dropdownHelperList: IHelper[] = [];
  searchValue = '';
  isUpdated = false;

  onChangeListener: (fn: any) => void = () => {};
  onTouchedListener: () => void = () => {};
  constructor(
    private httpClient: HttpClient,
    private message: NzMessageService,
  ) {
    this.trMouseEnter = this.trMouseEnter.bind(this);
    this.trMouseLeave = this.trMouseLeave.bind(this);
  }

  @HostListener('window:resize')
  setInit(): void {
    this.clearTimer();
    this.timer = setTimeout(() => {
      this.setDataStyle();
    }, 300);
  }

  setDataStyle(): void {
    if (this.showAdd && !this.isAlwaysShowAdd) {
      const tr = this.containerRef?.nativeElement.parentNode?.parentNode?.parentNode;
      if (tr?.nodeName === 'TR') {
        this.tr = tr;
        this.addListener();
      }
    }

    let nameWrapperWidth = this.containerRef?.nativeElement.offsetWidth - 28;
    const leng = this.list.length;
    const moreWidth = 28 + 2;
    if (leng) {
      nameWrapperWidth -= moreWidth;
      if (nameWrapperWidth <= 0) {
        this.moreNum = leng + 1;
        return;
      }
    }
    for (let i = 0; i < leng; i++) {
      const item = this.list[i];
      const itemWidth = this.avatarType === 'fullName' ? (item.name.length * 12 + 10 + 2 + 2) : (24 + 2 + 2);
      nameWrapperWidth -= itemWidth;
      item.show = true;
      if (i >= 0 && nameWrapperWidth <= 0) {
        item.show = false;
        this.moreNum = leng - i;
        break;
      }
    }
  }

  ngOnInit(): void {
    this.setInit();
    this.showAdd = this.type === 1;
    // this.getList();
  }

  ngOnDestroy(): void {
    this.clearTimer();
    this.removeListener();
  }

  clearTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  addListener(): void {
    this.tr?.addEventListener('mouseenter', this.trMouseEnter);
    this.tr?.addEventListener('mouseleave', this.trMouseLeave);
  }

  removeListener(): void {
    this.tr?.removeEventListener('mouseenter', this.trMouseEnter);
    this.tr?.removeEventListener('mouseleave', this.trMouseLeave);
  }

  trMouseEnter(): void {
    this.isHover = true;
  }

  trMouseLeave(): void {
    this.isHover = false;
  }

  changeViewVisible(visible: boolean): void {
    if (!visible && this.isUpdated) {
      this.updateHelpers();
    }
  }

  changeAddVisibe(visible: boolean, isAdd: boolean): void {
    if (!isAdd) {
      return;
    }
    if (visible) {
      this.removeListener();
      this.isHover = true;
    } else {
      this.isHover = false;
      this.addListener();
      if (this.checkIsUpdate()) {
        this.updateHelpers();
      }
    }
  }

  checkIsUpdate(): boolean {
    return !(this.initList.length === this.list.length && this.list.every(item => this.initList.some(l => l.id === item.id)));
  }

  getList(): void {
    this.loadingList = true;
    this.httpClient.post('tower/user/helper/list', { key: ''}).subscribe(
      (res: any) => {
        if (res.code !== 200) {
          this.message.error(res.msg);
          return;
        }
        const list: IHelper[] = res.data || [];
        list.forEach(item => {
          item.selected = this.list.some(data => data.id === item.id);
        });
        this.allHelperList = this.dropdownHelperList = list;
      },
      error => {
        this.message.error(error.toString());
      },
      () => {
        this.loadingList = false;
      }
    );
  }

  searchValueChange(val: string): void {
    const reg = new RegExp(val, 'i');
    this.dropdownHelperList = val ? this.allHelperList.filter(item => reg.test(item.name)) : [...this.allHelperList];
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchValueChange(this.searchValue);
  }

  writeValue(val: IHelper[]): void {
    this.list = val;
    this.initList = _.cloneDeep(val);
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  onChange(evt: IHelper): void {
    evt.selected = !evt.selected;
    const index = this.list.findIndex(item => item.id === evt.id);
    index > -1 ? this.list.splice(index, 1) : this.list.push(evt);
    this.onChangeListener(this.list);
    this.onTouchedListener();
    this.setDataStyle();
  }

  deleteHelper(index: number): void {
    this.isUpdated = true;
    this.list.splice(index, 1);
    if (!this.list.length) {
      this.updateHelpers();
    }
  }

  updateHelpers(): void {
    if (!this.id) {
      return;
    }
    const messageId = this.message.loading('正在更新协作者...', { nzDuration: 0 }).messageId;
    const params = {
      id: this.id,
      helpers: this.list.map(item => item.id),
    };
    this.httpClient.post('tower/project/update/helpers', params).subscribe(
      (res: any) => {
        if (res.code !== 200) {
          this.message.error(res.msg);
          return;
        }
        this.message.success('更新协作者成功');
        this.isUpdated = false;
      },
      error => {
        this.message.error(error.toString());
      },
      () => {
        this.message.remove(messageId);
      }
    );
  }
}
