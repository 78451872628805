<div class="sub-title">找回密码</div>
<form nz-form [formGroup]="forgetForm" (ngSubmit)="forgetSubmit()">
  <nz-alert *ngIf="error" [nzType]="'error'" [nzMessage]="error" [nzShowIcon]="true" class="mg-b-24">
  </nz-alert>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="mobileErrorTip">
      <nz-input-group nzSize="large" nzPrefixIcon="mobile">
        <input nz-input formControlName="mobile" placeholder="你的手机号" />
      </nz-input-group>
      <ng-template #mobileErrorTip let-i>
        <ng-container *ngIf="i.errors&&i.errors.required">
          请输入手机号！
        </ng-container>
        <ng-container *ngIf="i.errors&&i.errors.pattern">
          手机号格式错误！
        </ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzErrorTip]="'请输入验证码！'">
      <nz-row [nzGutter]="8">
        <nz-col [nzSpan]="16">
          <nz-input-group nzSize="large" nzPrefixIcon="mail">
            <input nz-input formControlName="captcha" nzHasFeedback placeholder="验证码" />
          </nz-input-group>
        </nz-col>
        <!-- [disabled]="form.get('mobile').errors||count>0" -->
        <nz-col [nzSpan]="8">
          <button type="button" [ngStyle]="{'color':!!forgetForm.get('mobile')!.errors || count>0 ? '' : '#6489FF' }" nz-button nzSize="large"
            (click)="getCaptcha('forget')" [disabled]="!!forgetForm.get('mobile')!.errors || count>0"
            nzBlock 
            [nzLoading]='forgetLoading'
          >
            {{ count ? (count + 's') : ('获取验证码') }}
          </button>
        </nz-col>
      </nz-row>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzErrorTip="请输入密码">
      <nz-input-group nzSize="large" nzPrefixIcon="lock">
        <input (ngModelChange)='validateConfirmPassword()' autocomplete='new-password' type='password'
          nz-input formControlName="password" placeholder="新密码" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzErrorTip="两次输入的密码不同">
      <nz-input-group nzSize="large" nzPrefixIcon="lock">
        <input autocomplete='new-password' nz-input type="password" formControlName="confirm"
          placeholder="确认新密码" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-col [nzSpan]="12">
      <label nz-checkbox formControlName="remember">自动登录</label>
    </nz-col>
    <nz-col [nzSpan]="12" class="text-right">
      <a class="forgot" routerLink='/auth/login'>返回登录页</a>
    </nz-col>
  </nz-form-item>
  <nz-form-item>
    <button class="button-color" nz-button type="submit" nzType="primary" nzSize="large"  
      nzBlock [nzLoading]='forgetConfirmLoading'>确认</button>
  </nz-form-item>
</form>