<!--<div class="progress-wrap">-->
<!--  <nz-progress [nzPercent]="data.percent" nzStrokeWidth='20' [nzShowInfo]="false"></nz-progress>-->
<!--  <div class="progress-text">{{data.text}}</div>-->
<!--</div>-->


<!--<div class="text-wrap">-->
<!--  <a class="expansion" [href]="link">扩容 ></a>-->
<!--</div>-->
<div class="btn" (click)="logout()">退出登录</div>
