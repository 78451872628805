import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'component-log-list',
  templateUrl: 'log-list.component.html',
  styleUrls: ['log-list.component.less'],
})
export class LogListComponent implements OnInit {
  @Input() id: string | null = null;

  loading = false;
  logList: {
    message: string,
    created: string,
  }[] = [];
  constructor(
    private httpClient: HttpClient,
    private message: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    if (!this.id) {
      return;
    }

    this.loading = true;
    this.httpClient.post('tower/log/list', { id: this.id }).subscribe(
      (res: any) => {
        const { code, msg, data } = res;
        if (code !== 200) {
          this.message.error(msg);
          return;
        }
        this.logList = data;
      },
      (error) => {
        this.message.error(error.toString());
      },
      () => {
        this.loading = false;
      }
    );
  }
}
