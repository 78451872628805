import {Component, HostBinding} from '@angular/core';

@Component({
  selector: 'app-layout-fullscreen',
  templateUrl: './fullscreen.component.html',
  // tslint:disable-next-line: no-host-metadata-property
  // host: {
  //   '[class.alain-fullscreen]': 'true',
  // },
})
export class LayoutFullScreenComponent {
  @HostBinding('attr.class') class = 'width-p-100';
}
