import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TitleService } from '@service/title/title.service';
import { NzIconService } from 'ng-zorro-antd/icon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { HomeService } from './pages/home/home.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private modalSrv: NzModalService,
    private titleSrv: TitleService,
    private iconService: NzIconService,
    private homeService: HomeService,
    // private settingsService: SettingsService,
    // private notification: NzNotificationService,
    // private activatedRoute:ActivatedRoute,
    // private trackService: TrackService,
    // private sidebarSer:SidebarServiceService
  ) {
    this.iconService.fetchFromIconfont({
      scriptUrl: '//at.alicdn.com/t/font_1657352_20mfrp0lctt.js',
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.titleSrv.setTitle();
        this.modalSrv.closeAll();
      }
    });

    // 无密码用户初次登录提示弹框设置密码
    this.homeService.needPassword$.subscribe(needSetPassword => {
      if (needSetPassword) {
        setTimeout(() => {
          this.homeService.setPassword();
        }, 1000);
      }
    });
  }
}
