import {Inject, Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {EZ_SERVICE_TOKEN, TokenService} from './token.service';
import {EZ_STORE_TOKEN} from './local-storage.service';
import {IStore} from './interface';
import {SimpleTokenModel} from './token/simple/simple.model';
import {CheckSimple} from './token/helper';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private injector: Injector, @Inject(EZ_STORE_TOKEN) private store: IStore) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const model = this.injector.get(EZ_SERVICE_TOKEN).get() as SimpleTokenModel;
    return CheckSimple(model as SimpleTokenModel);
  }
}
