import { enableProdMode, ViewEncapsulation } from '@angular/core';
import "@angular/compiler"
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {environment} from './environments/environment';

function preloaderFinished(): void {
  const body = document.querySelector('body')!;
  const preloader = document.querySelector('.preloader')!;

  body.style.overflow = 'hidden';

  function remove(): void {
    // preloader value null when running --hmr
    if (!preloader) { return; }
    preloader.addEventListener('transitionend', () => {
      preloader.className = 'preloader-hidden';
    });

    preloader.className += ' preloader-hidden-add preloader-hidden-add-active';
  }

  (window as any).appBootstrap = () => {
    setTimeout(() => {
      remove();
      body.style.overflow = '';
    }, 100);
  };
}

preloaderFinished();

const bootstrap = () => {
  return platformBrowserDynamic().bootstrapModule(AppModule, {
    defaultEncapsulation: ViewEncapsulation.Emulated,
  }).then((res) => {
    if ((window as any).appBootstrap) {
      ( window as any).appBootstrap();
    }
    return res;
  });
};

bootstrap();

function disableConsole(): void {
  // Object.keys(window.console).forEach(v => {
  //   window.console[v] = () => {};
  // });
}

if (environment.production) {
  enableProdMode();
  disableConsole();
}


