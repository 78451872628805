<nz-spin class="log-wrap" [nzSpinning]='loading'>
  <cdk-virtual-scroll-viewport itemSize="69" class="log-content">
    <div *cdkVirtualFor="let log of logList" class="log-item">
      <div >
        {{log.message}}
      </div>
      <span>{{log.created | date:'yyyy-MM-dd HH:mm:ss'}}</span>
    </div>
  </cdk-virtual-scroll-viewport>
  <nz-empty style="position: absolute;top: 40px;left: 30%;" *ngIf="!loading&&logList&&logList.length===0"></nz-empty>
</nz-spin>
