<div class="alain-default__progress-bar" *ngIf="isFetching"></div>
<div class="d-flex width-p-100 height-p-100">
  <div class="d-flex flex-1 width-p-100 height-p-100">
    <div class="ez-header" [ngClass]="{help:displayHelp}">
      <layout-header></layout-header>
    </div>
    <div class="ez-content">
      <app-layout-sidebar class="ez-aside" *ngIf="settingsService.roleId!==4"></app-layout-sidebar>
      <section class="content" [style]="settingsService.roleId===4?'width:100%;margin-left:0;':''">
        <router-outlet></router-outlet>
      </section>
    </div>
    <!-- <div style="height: 1px; border-bottom: 1px solid red"></div>
    <div class="d-flex width-p-100">
      <app-layout-sidebar
        class="ez-aside"
        *ngIf="!settingsService.isFullScreen"
      ></app-layout-sidebar>
      <section
        class="content"
        [ngClass]="{
          content__fullscreen: settingsService.isFullScreen
        }"
      >
        <router-outlet></router-outlet>
      </section>
    </div> -->
  </div>
</div>
