import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { Menu } from '@service/menu/interface';
import { MenuService } from '@service/menu/menu.service';
import { WINDOW } from '@app/utils/win_tokens';
import { SettingsService } from '@service/settings/settings.service';
import { ExpansionService } from '../expansion/expansion.service';

// import { Nav } from './sidebar-nav.types';

const SHOWCLS = 'sidebar-nav__floating-show';
const FLOATINGCLS = 'sidebar-nav__floating';

interface Nav extends Menu {
  /**
   * 菜单类型，无须指定由 Service 自动识别
   * 1：链接
   * 2：外部链接
   * 3：链接（子菜单）
   */
  _type?: number;
  /**
   * 是否选中
   */
  _selected?: boolean;
  /**
   * 是否隐藏菜单
   */
  _hidden?: boolean;
  /**
   * 是否打开
   */
  _open?: boolean;

  _depth?: number;

  _needIcon?: boolean;

  [key: string]: any;
}

@Component({
  selector: 'app-sidebar-nav',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['nav-bar.component.less'],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '(click)': '_click()',
    '(document:click)': '_docClick()',
  },
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidebarNavComponent implements OnInit, OnDestroy {
  private bodyEl: HTMLBodyElement | any;
  private unsubscribe$ = new Subject<void>();
  private floatingEl: HTMLDivElement | any;
  list: Nav[] = [];

  @Input() @InputBoolean() disabledAcl = false;
  @Input() @InputBoolean() autoCloseUnderPad = true;
  @Input() @InputBoolean() recursivePath = true;
  @Input() @InputBoolean() openStrictly = false;
  // tslint:disable-next-line:no-output-native
  @Output() readonly select = new EventEmitter<Menu>();

  get collapsed(): boolean {
    return this.settings.layout.collapsed;
  }

  constructor(
    private menuSrv: MenuService,
    private settings: SettingsService,
    private router: Router,
    private render: Renderer2,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private expansionService: ExpansionService,
    @Inject(DOCUMENT) private doc: any,
    @Inject(WINDOW) private win: Window,
  ) { }

  private getLinkNode(node: HTMLElement): HTMLElement | null {
    node = node.nodeName === 'A' ? node : (node.parentNode as HTMLElement);
    return node.nodeName !== 'A' ? null : node;
  }

  private floatingAreaClickHandle(e: MouseEvent): boolean {
    e.stopPropagation();
    const linkNode = this.getLinkNode(e.target as HTMLElement);
    if (linkNode == null) {
      return false;
    }
    const tid = (linkNode && linkNode.dataset) ? linkNode.dataset.id : undefined;
    const id = tid ? (+tid) : NaN;
    // Should be ingore children title trigger event
    if (isNaN(id)) {
      return false;
    }

    let item: Nav = {};
    this.menuSrv.visit(this.list, i => {
      if (!item && i.__id === id) {
        item = i;
      }
    });
    this.to(item ? item : {});
    this.hideAll();
    e.preventDefault();
    return false;
  }

  private clearFloatingContainer(): void {
    if (!this.floatingEl) { return; }
    this.floatingEl.removeEventListener('click', this.floatingAreaClickHandle.bind(this));
    // fix ie: https://github.com/ng-alain/delon/issues/52
    if (this.floatingEl.hasOwnProperty('remove')) {
      this.floatingEl.remove();
    } else if (this.floatingEl.parentNode) {
      this.floatingEl.parentNode.removeChild(this.floatingEl);
    }
  }

  private genFloatingContainer(): void {
    this.clearFloatingContainer();
    this.floatingEl = this.render.createElement('div');
    this.floatingEl.classList.add(FLOATINGCLS + '-container');
    this.floatingEl.addEventListener('click', this.floatingAreaClickHandle.bind(this), false);
    this.bodyEl.appendChild(this.floatingEl);
  }

  private genSubNode(linkNode: HTMLLinkElement, item: Nav): HTMLUListElement {
    const id = `_sidebar-nav-${item.__id}`;
    // tslint:disable-next-line:no-non-null-assertion
    const childNode = item.badge ? linkNode.nextElementSibling!.nextElementSibling! : linkNode.nextElementSibling!;
    const node = childNode.cloneNode(true) as HTMLUListElement;
    node.id = id;
    node.classList.add(FLOATINGCLS);
    node.addEventListener(
      'mouseleave',
      () => {
        node.classList.remove(SHOWCLS);
      },
      false,
    );
    this.floatingEl?.appendChild(node);
    return node;
  }

  private hideAll(): void {
    const allNode = this.floatingEl.querySelectorAll('.' + FLOATINGCLS);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < allNode.length; i++) {
      allNode[i].classList.remove(SHOWCLS);
    }
  }

  // calculate the node position values.
  private calPos(linkNode: HTMLLinkElement, node: HTMLUListElement): void {
    const rect = linkNode.getBoundingClientRect();
    // bug: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/14721015/
    const scrollTop = Math.max(this.doc.documentElement.scrollTop, this.bodyEl.scrollTop);
    const docHeight = Math.max(this.doc.documentElement.clientHeight, this.bodyEl.clientHeight);
    let offsetHeight = 0;
    if (docHeight < rect.top + node.clientHeight) {
      offsetHeight = rect.top + node.clientHeight - docHeight;
    }
    node.style.top = `${rect.top + scrollTop - offsetHeight}px`;
    node.style.left = `${rect.right + 5}px`;
  }

  showSubMenu(e: MouseEvent, item: Nav): void {
    if (this.collapsed !== true) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      e.preventDefault();
      const linkNode = e.target as Element;
      this.genFloatingContainer();
      const subNode = this.genSubNode(linkNode as HTMLLinkElement, item);
      this.hideAll();
      subNode.classList.add(SHOWCLS);
      this.calPos(linkNode as HTMLLinkElement, subNode);
    });
  }

  to(item: Menu): void {
    this.expansionService.setData({ percent: 10, text: item.text ? item.text : '' });
    this.select.emit(item);
    if (item.disabled) { return; }

    if (item.externalLink) {
      if (item.target === '_blank') {
        this.win.open(item.externalLink);
      } else {
        this.win.location.href = item.externalLink;
      }
      return;
    }
    this.ngZone.run(() => this.router.navigateByUrl(item.link ? item.link : ''));
  }

  toggleOpen(item: Nav): void {
    if (item && item.children && item.children.length < 1) {
      this.to(item);
    } else {
      if (!this.openStrictly) {
        this.menuSrv.visit(this.list, i => {
          if (i !== item) { i._open = false; }
        });
        let pItem = item.__parent;
        while (pItem) {
          pItem._open = true;
          pItem = pItem.__parent;
        }
      }
      // item._open = !item._open;
      item.collapse = !item.collapse;
      this.cdr.markForCheck();
    }
  }

  _click(): void {
    if (this.isPad && this.collapsed) {
      this.openAside(false);
      this.hideAll();
    }
  }

  _docClick(): void {
    if (this.collapsed) {
      this.hideAll();
    }
  }

  private openedByUrl(url: string | null): void {
    const { menuSrv, recursivePath, openStrictly } = this;
    let findItem = menuSrv.getHit(this.menuSrv.menus, url ? url : '', recursivePath, i => {
      i._selected = false;
      if (!openStrictly) {
        i._open = false;
      }
    });
    if (findItem == null) { return; }

    do {
      findItem._selected = true;
      if (!openStrictly) {
        findItem._open = true;
      }
      findItem = findItem.__parent;
    } while (findItem);
  }

  ngOnInit(): void {
    const t = localStorage.getItem('_token');
    const userType = JSON.parse(t ? t : '').userType;

    const { doc, router, unsubscribe$, menuSrv, cdr } = this;
    this.bodyEl = doc.querySelector('body');
    this.openedByUrl(router.url);
    this.ngZone.runOutsideAngular(() => this.genFloatingContainer());
    menuSrv.change.pipe(takeUntil(unsubscribe$)).subscribe(data => {
      menuSrv.visit(data, (i: Nav, _p, depth) => {
        i._text = this.sanitizer.bypassSecurityTrustHtml(i.text ? i.text : '');
        i._needIcon = (depth ? depth : 0) <= 1;
        if (!i._aclResult) {
          if (this.disabledAcl) {
            i.disabled = true;
          } else {
            i._hidden = true;
          }
        }
        if (this.openStrictly) {
          i._open = i.open != null ? i.open : false;
        }
      });
      if (this.settings.roleId === 3) {
        this.list = menuSrv.menus.filter(item => {
          return item.userType === 0;
        });
      } else if (this.settings.roleId === 1) {
        // 管理员
        this.list = menuSrv.menus;
      } else {
        this.list = menuSrv.menus.filter(item => {
          return item.userType !== 2;
        });
      }

      cdr.detectChanges();
    });
    router.events.pipe(takeUntil(unsubscribe$)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.openedByUrl(e.urlAfterRedirects.split(';')[0]);
        this.underPad();
        this.cdr.detectChanges();
      }
    });
    this.underPad();
  }

  ngOnDestroy(): void {
    const { unsubscribe$ } = this;
    unsubscribe$.next();
    unsubscribe$.complete();
    this.clearFloatingContainer();
  }

  // #region Under pad

  private get isPad(): boolean {
    return window.innerWidth < 768;
  }

  private underPad(): void {
    if (this.autoCloseUnderPad && this.isPad && !this.collapsed) {
      setTimeout(() => this.openAside(true));
    }
  }

  private openAside(status: boolean): void {
    this.settings.setLayout('collapsed', status);
  }

  // #endregion
}
