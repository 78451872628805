<div class="container">
  <div class="wrap">
    <div class="login-box">
      <div class="card cover">
        <div class="img-container">
          <div class="d-flex flex-1 align-items-center flex-column app-title">
            <img src="./assets/images/logo.png" alt="">
            <span class="app-name">塔机方案系统</span>
            <span class="app-eName">Project Management System</span>
          </div>
        </div>
      </div>
      <div class="card login-wrap">
        <div class="container">
          <div class="c-login-box">
            <a class="login-logo">
              <div class="title">塔机方案系统</div>
            </a>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>