import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { PreloadingStrategy } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, loadMe: () => Observable<boolean>): Observable<boolean> {
    return timer(5000).pipe(
      flatMap(_ => {
        return loadMe();
      })
    );
  }
}
