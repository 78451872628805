import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ITokenService } from 'src/app/service/interface';
import { SettingsService } from 'src/app/service/settings/settings.service';
import { EZ_SERVICE_TOKEN } from 'src/app/service/token.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.less'],
})
export class HeaderComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  isFetching = false;
  displayHelp = false;
  st = {
    background: 'blue;'
  };

  constructor(
    private router: Router,
    private message: NzMessageService,
    public settingsService: SettingsService,
    private httpClient: HttpClient,
    @Inject(EZ_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    // scroll to top in change page
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(evt => {
      if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
        this.isFetching = true;
      }
      if (evt instanceof NavigationError || evt instanceof NavigationCancel) {
        this.isFetching = false;
        if (evt instanceof NavigationError) {
          message.error(`无法加载${evt.url}路由`, { nzDuration: 1000 * 3 });
        }
        return;
      }
      if (!(evt instanceof NavigationEnd || evt instanceof RouteConfigLoadEnd)) {
        return;
      }
      if (this.isFetching) {
        setTimeout(() => {
          this.isFetching = false;
        }, 100);
      }
    });
  }

  ngOnInit(): void {}

  hideHelp(): void {
    this.displayHelp = false;
  }

  showHelp(): void {
    this.displayHelp = true;
  }

  logout(): void {
    this.httpClient.post('passport/logout', null).subscribe(
      (res: any) => {
        const { code, msg, data } = res;
        if (code !== 200) {
          this.message.error(msg);
          return;
        }
        this.tokenService.clear();
        localStorage.clear();
        // this.aclService.setFull(false);
        this.router.navigateByUrl(this.tokenService.login_url as string);
      },
      error => {
        this.message.warning(error.toString());
      }
    );
  }
}
