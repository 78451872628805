<div class="height-p-100 page-container" [ngClass]="{'page-container__has-title': !!prefix}">
  <div *ngIf="prefix" class="page-header">
    <div
      class="d-inline-block text-ellipsis page-title"
      [ngClass]="{'page-title__back': !!backUrl}"
      (click)="onBack()"
    >
      <i *ngIf="!!backUrl" class="back-icon" nz-icon nzType="left" nzTheme="outline"></i>{{ prefix }}
  </div>
  </div>
  <div [ngStyle]="ezContentStyle" class="height-p-100 page-content" 
    [ngClass]="{
      'page-content__has-title': !!prefix,
      'page-content__no-padding': noContentPadding
    }">
    <ng-content></ng-content>
  </div>
</div>
