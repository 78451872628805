<ng-template #icon let-a>
  <!-- <ng-container *ngIf="a" [ngSwitch]="a.type">
    <i nz-icon *ngSwitchCase="'icon'" class="sidebar-nav__item-icon" [nzType]="a.value" [nzTheme]="a.theme"
      [nzSpin]="a.spin" [nzTwotoneColor]="a.twoToneColor" [nzIconfont]="a.iconfont"></i>
    <i *ngSwitchCase="'iconfont'" class="sidebar-nav__item-icon" nz-icon [nzIconfont]="a.iconfont"></i>
    <img *ngSwitchCase="'img'" src="{{ a.value }}" class="sidebar-nav__item-icon sidebar-nav__item-img">
    <i *ngSwitchDefault class="sidebar-nav__item-icon {{ a.value }}"></i>
  </ng-container> -->
  <i class="sidebar-nav__item-icon" nz-icon [nzIconfont]="a.icon.value"></i>
</ng-template>
<ul class="sidebar-nav">
  <ng-container *ngFor="let child1 of list">
    <li *ngIf="child1._hidden !== true" class="sidebar-nav__item" [class.sidebar-nav__open]="!child1.collapse">
      <!-- link -->
      <!-- has children link -->
      <a (click)="toggleOpen(child1)" (mouseenter)="showSubMenu($event, child1)"
        [class.sidebar-nav__selected]="child1._selected" class="sidebar-nav__item-link">
        <span (click)="to(child1)">
          <ng-template [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{$implicit: child1}"></ng-template>
          <span class="sidebar-nav__item-text">{{ child1.text }}</span>
          <i *ngIf="child1.children?.length" class="sidebar-nav__sub-arrow"></i>
        </span>
      </a>
      <!-- badge -->
      <div *ngIf="child1.badge" title="{{child1.badge}}" class="badge badge-{{child1.badgeStatus}}"
        [class.badge-dot]="child1.badgeDot">
        <em>{{child1.badge}}</em>
      </div>
      <!-- Level 2 -->
      <ul class="sidebar-nav sidebar-nav__sub flex-wrap">
        <ng-container *ngFor="let child2 of child1.children">
          <li *ngIf="child2._hidden !== true" class="sidebar-nav__item" [class.sidebar-nav__selected]="child2._selected"
            [class.sidebar-nav__open]="child2._open">
            <!-- link -->
            <ng-template [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{$implicit: child2.icon}"></ng-template>
            <a *ngIf="child2._type <= 2" (click)="to(child2)" [attr.data-id]="child2.__id"
              class="sidebar-nav__sub-link" [ngClass]="{'sidebar-nav__item-disabled': child2.disabled}">
              {{ child2.text }}
            </a>
            <!-- has children link -->
            <a *ngIf="child2._type === 3" (click)="toggleOpen(child2)" class="sidebar-nav__item-link">
              {{ child2.text }}
              <i class="sidebar-nav__sub-arrow"></i>
            </a>
            <!-- badge -->
            <div *ngIf="child2.badge" title="{{child2.badge}}" class="badge badge-{{child2.badgeStatus}}"
              [class.badge-dot]="child2.badgeDot">
              <em>{{child2.badge}}</em>
            </div>
            <!-- Level 3 -->
            <ul *ngIf="child2._type === 3" class="sidebar-nav sidebar-nav__sub sidebar-nav__depth{{child2._depth+1}}">
              <ng-container *ngFor="let child3 of child2.children">
                <li *ngIf="child3._hidden !== true" class="sidebar-nav__item"
                  [class.sidebar-nav__selected]="child3._selected" [class.sidebar-nav__open]="child3._open">
                  <!-- link -->
                  <a *ngIf="child3._type === 1" (click)="to(child3)" [attr.data-id]="child3.__id"
                    class="sidebar-nav__item-link"
                    [ngClass]="{'sidebar-nav__item-disabled': child3.disabled}">{{ child3.text }}</a>
                  <!-- external link -->
                  <a *ngIf="child3._type === 2" href="{{ child3.externalLink }}" target="{{ child3.target }}"
                    data-type="external" class="sidebar-nav__item-link">{{ child3.text }}</a>
                  <!-- badge -->
                  <div *ngIf="child3.badge" title="{{child3.badge}}" class="badge badge-{{child3.badgeStatus}}"
                    [class.badge-dot]="child3.badgeDot">
                    <em>{{child3.badge}}</em>
                  </div>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
      <div class="sidebar-nav__splitter"></div>
    </li>
  </ng-container>
</ul>
