import { Route } from '@angular/router';
import { LayoutFullScreenComponent } from '../layout/fullscreen/fullscreen.component';

const fullScreenNotAuthRoute: Route = {
  path: '',
  component: LayoutFullScreenComponent,
  children: [
    {
      path: 'auth',
      loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule),
    },
  ],
};

export default fullScreenNotAuthRoute;
