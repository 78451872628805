import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { App, CorpInfo, Layout, SettingsNotify, User, UserInfo } from './interface';

export const LAYOUT = 'layout';

export const USER = 'user';

export const APP = 'app';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private notify$ = new Subject<SettingsNotify>();
  private _app: App | null = null;
  private _user: User | null = null;
  private _layout: Layout | null = null;
  private _userInfo: UserInfo | null = null;
  private _corpInfo: CorpInfo | null = null;
  private _isMobile = false;
  private _isFullScreen = false;
  private _is360IE = false;
  private _roleId = 0;
  private _corpName = '';

  private get(key: string): any {
    return JSON.parse(localStorage.getItem(key) || 'null') || null;
  }

  private set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // 是否是管理员
  public get isAdmin(): boolean {
    return this.roleId === 1;
  }

  // 是否管理员或技术经理
  public get isManager(): boolean {
    return [1, 2].includes(this.roleId);
  }

  // 是否技术员
  public get isTechnician(): boolean {
    return this.roleId === 3;
  }

  // 是否安拆工
  public get isWorker(): boolean {
    return this.roleId === 4;
  }

  get layout(): Layout {
    if (!this._layout) {
      this._layout = {
        fixed: true,
        collapsed: false,
        boxed: false,
        lang: null,
        ...this.get(LAYOUT),
      };
      this.set(LAYOUT, this._layout);
    }
    return this._layout as Layout;
  }

  get app(): App {
    if (!this._app) {
      this._app = {
        year: new Date().getFullYear(),
        ...this.get(APP),
      };
      this.set(APP, this._app);
    }
    return this._app as App;
  }

  get user(): User {
    if (!this._user) {
      this._user = { ...this.get(USER) };
      this.set(USER, this._user);
    }
    return this._user as User;
  }

  get userInfo(): UserInfo {
    return this._userInfo as UserInfo;
  }

  get corpInfo(): CorpInfo {
    return this._corpInfo as CorpInfo;
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

  get notify(): Observable<SettingsNotify> {
    return this.notify$.asObservable();
  }

  get isFullScreen(): boolean {
    return this._isFullScreen;
  }

  get is360IE(): boolean {
    return this._is360IE;
  }

  get roleId(): number {
    return this._roleId;
  }

  get corpName(): string {
    return this._corpName;
  }

  setIsFullScreen(isFullScreen: boolean): void {
    this._isFullScreen = isFullScreen;
  }

  setLayout(name: string | Layout, value?: any): boolean {
    if (typeof name === 'string') {
      this.layout[name] = value;
    } else {
      this._layout = name;
    }
    this.set(LAYOUT, this._layout);
    this.notify$.next({ type: 'layout', name, value } as any);
    return true;
  }

  setApp(value: App): boolean {
    this._app = value;
    this.set(APP, value);
    this.notify$.next({ type: 'app', value });
    return true;
  }

  setUser(value: User): boolean {
    this._user = value;
    this.set(USER, value);
    this.notify$.next({ type: 'user', value });
    return true;
  }

  setUserInfo(value: UserInfo): boolean {
    this._userInfo = value;
    this.notify$.next({ type: 'user', value });
    return true;
  }

  setCorpInfo(value: CorpInfo): boolean {
    this._corpInfo = value;
    this.notify$.next({ type: 'corp', value });
    return true;
  }

  setIsMobile(bool: boolean): void {
    this._isMobile = bool;
  }

  setIs360IE(bool: boolean): void {
    this._is360IE = bool;
  }

  setRoleId(roleId: number): void {
    this._roleId = roleId;
  }

  setCorpName(corpName: string): void {
    this._corpName = corpName;
  }
}
